import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
// const CreateCatalogue = lazy(() => import('../../container/cat/FormLayout'));
// import { createCatalogue } from '../../container/cataloguesManagement/CreateCatalogue';

const CataloguesList = lazy(() => import('../../container/cataloguesManagement/CataloguesList'));
const CreateCatalogue = lazy(() => import('../../container/cataloguesManagement/CreateCatalogue'));
const DetailsCatalogue = lazy(() => import('../../container/cataloguesManagement/DetailsCatalogue'));
const EditCatalogue = lazy(() => import('../../container/cataloguesManagement/EditCatalogue'));

// import Detail from ('../../container/cataloguesManagement/FormLayout');
// const UserDetails = lazy(() => import('../../container/userManagement/UserDetails'));

function UserManagement() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={CataloguesList} />
      <Route path={`${path}/crear`} component={CreateCatalogue} />
      <Route path={`${path}/detalles/:catalogueId`} component={DetailsCatalogue} />
      <Route path={`${path}/editar/:catalogueId`} component={EditCatalogue} />
      {/* <Route path={`${path}/usuario/:userId`} component={UserDetails} /> */}
    </Switch>
  );
}

export default UserManagement;
