// import Cookies from 'js-cookie';
import { Api } from '../../config/api/axiosInterceptors';
import Storage from '../../utils/storage';
import actions from './actions';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = () => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      // const response = await Api.post('/login', credentials);
      // const { token, user } = response.data;
      // console.log(response);

      // // Guarda los tokens en el almacenamiento local
      // Storage.setItem('token', token);
      // Storage.setItem('user', user);

      // const role = Storage.getUserRole();
      // history.push(`${typeRoles[role]}`);
      // window.location.href = `${typeRoles[response.data.user.role_name]}`;

      return dispatch(loginSuccess(true));
    } catch (err) {
      dispatch(loginErr(err));
      throw err;
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Storage.clear();
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { logOut, login };
