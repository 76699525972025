import { emailRegex, phoneRegex, amountRegex, integerRegex } from './regex';

export const generateRequiredRule = (fieldName) =>
  // Metodo para definir el campo como requerido
  ({ required: true, message: `Por favor ingresa el campo ${fieldName}` });

export const generateCommonRules = (fieldName) => [
  // Metodo para campos que comparten mismas validaciones string y 255 caracteres
  generateRequiredRule(fieldName),
  { max: 255, message: `El campo ${fieldName} debe tener como máximo 255 caracteres` },
];

export const passwordRule = [
  generateRequiredRule('contraseña'),
  { min: 8, message: 'El campo contraseña debe tener como mínimo 8 caracteres' },
  { max: 255, message: 'El campo contraseña debe tener como máximo 255 caracteres' },
];

export const emailRules = [
  generateRequiredRule('correo electrónico'),
  { max: 50, message: `El campo correo electrónico debe tener como máximo 50 caracteres` },
  { pattern: emailRegex, message: 'El campo correo electrónico debe tener un formato válido' },
];

export const phoneRules = [
  generateRequiredRule('teléfono'),
  {
    pattern: phoneRegex,
    message:
      'El campo teléfono debe tener 8 o 10 dígitos, considera el formato 12345678, 1234567890, 12-34-56-78 ó 12-34-56-78-90',
  },
];

export const amountRules = (fieldName) => [
  generateRequiredRule(fieldName),
  {
    pattern: amountRegex,
    message: `El campo ${fieldName} debe ser un número de hasta 9 dígitos, sin negativos y 2 decimales opcionales.`,
  },
];

export const amountNoRquiredRules = (fieldName) => [
  {
    pattern: amountRegex,
    message: `El campo ${fieldName} debe ser un número de hasta 9 dígitos, sin negativos y 2 decimales opcionales.`,
  },
];

export const amountRulesWithMin = (fieldName, minValue) => [
  ...amountRules(fieldName),
  {
    validator: (_, value) => {
      if (!value || parseFloat(value) >= minValue) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(`El campo ${fieldName} debe ser al menos ${minValue}`));
    },
  },
];

export const integerRules = (fieldName) => [
  generateRequiredRule(fieldName),
  {
    pattern: integerRegex,
    message: `El campo ${fieldName} debe ser un número de hasta 9 dígitos, sin decimales ni negativos`,
  },
];

export const integerRulesWithMin = (fieldName, minValue) => [
  ...integerRules(fieldName),
  {
    validator: (_, value) => {
      if (!value || parseFloat(value) >= minValue) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(`El campo ${fieldName} debe ser al menos ${minValue}`));
    },
  },
];

export const integerNoRquiredRules = (fieldName) => [
  {
    pattern: integerRegex,
    message: `El campo ${fieldName} debe ser un número de hasta 9 dígitos, sin decimales ni negativos`,
  },
];

export const characterLimitField = (fieldName) => [
  // Metodo para campos que comparten misma validacion de 255 caracteres
  { max: 255, message: `El campo ${fieldName} debe tener como máximo 255 caracteres` },
];

export const textareaCommonRules = (fieldName, size = 1000) => [
  // Metodo para campos que comparten mismas validaciones string y 1000 caracteres
  { max: size, message: `El campo ${fieldName} debe tener como máximo ${size} caracteres` },
];

export const textareaCommonRequiredRules = (fieldName, size = 1000) => [
  // Metodo para campos que comparten mismas validaciones string y 1000 caracteres
  generateRequiredRule(fieldName),
  { max: size, message: `El campo ${fieldName} debe tener como máximo ${size} caracteres` },
];
