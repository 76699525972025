// import actions from './actions';
// const { LOGIN, LOGOUT } = actions;

import Storage from '../../utils/storage';
import { LOGIN, LOGOUT, LOAD_AUTH_STATE } from './actions';

const storedAuth = Storage.getItem('user');

const initialState = storedAuth || {
  isAuthenticated: false,
  name: null,
  role: null,
};

const authUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      const newState = {
        isAuthenticated: true,
        id: action?.payload?.id,
        prospect_id: action?.payload?.prospect_id,
        role: action?.payload?.roles[0].name,
        name: action?.payload?.name,
        last_name: action?.payload?.last_name,
        second_last_name: action?.payload?.second_last_name,
        email: action?.payload?.email,
        cell_phone: action?.payload?.cell_phone,
      };
      // localStorage.setItem('user', JSON.stringify(newState));
      // Storage.setItem('token', token);
      Storage.setItem('user', newState);
      return newState;
    case LOGOUT:
      Storage.removeItem('token');
      Storage.removeItem('user');
      return initialState;
    case LOAD_AUTH_STATE:
      return action.payload || state;
    default:
      return state;
  }
};

export default authUserReducer;
