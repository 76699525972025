// actions/userActions.js

import Storage from '../../utils/storage';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOAD_AUTH_STATE = 'LOAD_AUTH_STATE';

export const login = (user) => ({
  type: 'LOGIN',
  payload: user,
});

export const logout = () => ({
  type: 'LOGOUT',
});

export const loadAuthState = () => {
  const storedAuth = Storage.getItem('user');
  return {
    type: LOAD_AUTH_STATE,
    payload: storedAuth,
  };
};
