import CryptoJS from 'crypto-js';
const secretKey = process.env.REACT_APP_SECRET_KEY

const Storage = {
  // Guarda datos en el localStorage
  setItem: (key, value) => {
    // if (typeof value === 'object') {
    //   localStorage.setItem(key, JSON.stringify(value));
    // } else {
    //   localStorage.setItem(key, value);
    // }
    const stringValue = typeof value === 'object' ? JSON.stringify(value) : value;
    const encryptedValue = CryptoJS.AES.encrypt(stringValue, secretKey).toString();
    localStorage.setItem(key, encryptedValue);
  },

  // Obtiene datos del localStorage
  getItem: (key) => {
    // const value = localStorage.getItem(key);
    // try {
    //   return JSON.parse(value);
    // } catch (e) {
    //   return value;
    // }
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) {
      return null;
    }
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedValue);
    } catch (e) {
      console.error('Error decrypting data:', e);
      return null;
    }
  },

  // Elimina un dato del localStorage
  removeItem: (key) => {
    localStorage.removeItem(key);
  },

  // Obtiene datos del access_token
  getAccessToken: () => {
    const value = Storage.getItem('token');
    return value?.access_token;
  },

  // Obtiene datos del refresh_token
  getRefreshToken: () => {
    const value = Storage.getItem('token');
    return value?.refresh_token;
  },

  getUserRole: () => {
    const value = Storage.getItem('user');
    return value?.role_name;
  },

  getUserName: () => {
    const value = Storage.getItem('user');
    // return `${value?.name + value?.last_name}`;
    return value?.name;
  },

  // Limpia todo el localStorage
  clear: () => {
    localStorage.clear();
  },
};

export default Storage;
