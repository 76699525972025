import { Empty, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { ChartjsHorizontalChart } from '../../../components/charts/chartjs';
import { Api } from '../../../config/api/axiosInterceptors';
import { CardBarChart } from '../style';
import { createDashboardQueryParams } from '../../../utils/utils';

function SalesStatus({ project_id, start_date, end_date }) {
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);

  const getSalesStatus = async () => {
    setLoading(true);
    try {
      const queryParams = createDashboardQueryParams({ project_id, start_date, end_date });
      const response = await Api.get(`/dashboard/estates-data?${queryParams}`);
      const { total, disponible, apartado, vendido } = response.data.estates_statuses;
      const labels = ['Inmuebles disponibles', 'Inmuebles apartados', 'Inmuebles vendidos'];

      const getPercentage = (value) => {
        return ((value * 100) / total).toFixed(2);
      };

      const datasets = [
        {
          data: [getPercentage(disponible), getPercentage(apartado), getPercentage(vendido)],
          backgroundColor: ['#F8D247', '#F8A647', '#F66B20'],
          barPercentage: 0.6,
        },
      ];

      setChartData({ labels, datasets });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSalesStatus();
  }, [project_id, start_date, end_date]);

  return (
    <>
      <Cards title="ESTATUS DE VENTAS" size="large">
        {loading ? (
          <Skeleton active />
        ) : chartData.datasets?.length > 0 && chartData.datasets[0].data.length > 0 ? (
          <ChartjsHorizontalChart datasets={chartData.datasets} labels={chartData.labels} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Cards>
    </>
  );
}

export default SalesStatus;
