import { Avatar } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Api } from '../../../config/api/axiosInterceptors'; // Import Api
import { logout } from '../../../redux/authUser/actions';
import avatar from '../../../static/img/balken/avatar_BALKEN.png';
import { Popover } from '../../popup/popup';
import { InfoWraper, UserDropDwon } from './auth-info-style';

function AuthInfo() {
  const dispatch = useDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();
  const page = `${path}/perfil`;

  const SignOut = async (e) => {
    e.preventDefault();
    try {
      await Api.post('/logout');
      history.push('/login');
      dispatch(logout());
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const redirectToProfile = () => {
    history.push(`${path}/perfil`);
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <Link to={page} className="user-dropdwon__links">
          <FeatherIcon icon="user" size={14} />
          <span>Mi perfil</span>
        </Link>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Cerrar sesión
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src={avatar} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
