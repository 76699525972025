import { Spin } from 'antd';
import React, { Suspense, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
import myProfile from '../../container/myProfileManagement/myProfile';
import ProspectsManagement from './prospectsManagement';
import SalesTrackingManagement from './salesTrackingManagement';
function Seller() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}/perfil`} component={myProfile} />
        <Route path={`${path}/prospectos`} component={ProspectsManagement} />
        <Route path={`${path}/seguimiento-venta`} component={SalesTrackingManagement} />
      </Suspense>
    </Switch>
  );
}

export default withAdminLayout(Seller);
