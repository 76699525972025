import { Col, DatePicker, Row, Select, Spin, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import CustomSelect from '../../components/custom-select/custom-select';
import HeaderCard from '../../components/headerCard/headerCard';
import { getAllPaginatedData } from '../../config/api/methodsByApi';
import useRole from '../../hooks/useRole';
import { Main } from '../styled';
import CardGrid from './overview/CardGrid';
import PaymentStatus from './overview/PaymentStatus';
import PaymentsByProject from './overview/PaymentsByProject';
import ProspectsByStage from './overview/ProspectsByStage';
import ProspectsWithoutProgressList from './overview/ProspectsWithoutProgressList';
import SalesStatus from './overview/SalesStatus';
import Sellers from './overview/Sellers';

const { TabPane } = Tabs;
const { Option } = Select;

function Dashboard() {
  const role = useRole();
  const isAdmin = role === 'administrador';
  const formatDate = 'DD/MM/YYYY';

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedEstate, setSelectedEstate] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [estates, setEstates] = useState([]);

  const [activeKey, setActiveKey] = useState(isAdmin ? 'contratos' : 'ventas');

  useEffect(() => {
    if (selectedProject) {
      setLoading(true);
      getAllPaginatedData(
        '/estates',
        'estates',
        //  setEstates,
        (data) => {
          const availableEstates = data.filter((estate) => estate.status == 'vendido');
          setEstates(availableEstates);
          setLoading(false);
        },
        `&project_id=${selectedProject}`,
      );
    }
  }, [selectedProject]);

  const handleStartDateChange = (date, dateString) => {
    setStartDate(dateString);
  };

  const handleEndDateChange = (date, dateString) => {
    setEndDate(dateString);
  };

  const handleProjectChange = (value) => {
    setSelectedProject(value || '');
    setSelectedEstate(null)
  };

  const handleEstateChange = (value) => {
    setSelectedEstate(value || '');
  };

  const handleClientChange = (value) => {
    console.log(value);
    setSelectedClient(value || '');
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const renderExtraElements = () => {
    return [
      <CustomSelect
        url="/projects"
        datakey="projects"
        placeholder="Selecciona un proyecto"
        className="page-header-select-filter"
        allowClear
        onChange={handleProjectChange}
      />,
      // Renderiza solo si el activeKey es 'contratos'
      activeKey === 'contratos' ? (
        <Select
          placeholder="Selecciona un inmueble"
          className="page-header-select-filter"
          value={selectedEstate}
          disabled={!selectedProject}
          allowClear
          onChange={handleEstateChange}
        >
          {estates.map((estate) => (
            <Option key={estate.id} value={estate.id}>
              {estate.name}
            </Option>
          ))}
        </Select>
      ) : null,
      activeKey === 'contratos' ? (
        <CustomSelect
          url="/users"
          datakey="users"
          param="&rol=cliente"
          placeholder="Selecciona un cliente"
          className="page-header-select-filter"
          allowClear
          onChange={handleClientChange}
        />
      ) : null,
      <DatePicker format={formatDate} placeholder="Fecha inicial" onChange={handleStartDateChange} />,
      <DatePicker format={formatDate} placeholder="Fecha final" onChange={handleEndDateChange} />,
    ].filter(Boolean); // Filtra los elementos nulos
  };

  return (
    <>
      <Spin spinning={loading}>
        <HeaderCard title="Inicio" extraElements={renderExtraElements()} />

        <Main>
          <Tabs defaultActiveKey={isAdmin ? 'contratos' : 'ventas'} className="custom-tabs" onChange={handleTabChange}>
            {isAdmin && (
              <TabPane tab="Contratos" key="contratos">
                <Row gutter={25}>
                  <Col xxl={24} md={24} sm={24}>
                    <Row gutter={25}>
                      <CardGrid
                        isAdmin={isAdmin}
                        xxl={8}
                        lg={8}
                        md={12}
                        xs={24}
                        type="contratos"
                        project_id={selectedProject}
                        estate_id={selectedEstate}
                        client_id={selectedClient}
                        start_date={startDate}
                        end_date={endDate}
                      />
                    </Row>
                  </Col>

                  <Col xxl={12} lg={12} md={12} xs={24}>
                    <PaymentStatus
                      project_id={selectedProject}
                      estate_id={selectedEstate}
                      client_id={selectedClient}
                      start_date={startDate}
                      end_date={endDate}
                    />
                  </Col>

                  <Col xxl={12} lg={12} md={12} xs={24}>
                    <PaymentsByProject
                      project_id={selectedProject}
                      estate_id={selectedEstate}
                      client_id={selectedClient}
                      start_date={startDate}
                      end_date={endDate}
                    />
                  </Col>
                </Row>
              </TabPane>
            )}

            <TabPane tab="Ventas" key="ventas">
              <Row gutter={25}>
                <Col xxl={24} md={24} sm={24} xs={24}>
                  <Row gutter={25}>
                    <CardGrid
                      isAdmin={isAdmin}
                      lg={6}
                      md={12}
                      xs={24}
                      project_id={selectedProject}
                      start_date={startDate}
                      end_date={endDate}
                    />
                  </Row>
                </Col>

                <Col xxl={12} lg={12} md={12} xs={24}>
                  <SalesStatus project_id={selectedProject} start_date={startDate} end_date={endDate} />
                </Col>

                <Col xxl={12} lg={12} md={12} xs={24}>
                  <ProspectsByStage project_id={selectedProject} start_date={startDate} end_date={endDate} />
                </Col>

                <Col xxl={24} lg={24} xs={24}>
                  <Sellers />
                </Col>

                <Col xxl={24} lg={24} xs={24}>
                  <ProspectsWithoutProgressList
                    project_id={selectedProject}
                    start_date={startDate}
                    end_date={endDate}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Main>
      </Spin>
    </>
  );
}

export default Dashboard;
