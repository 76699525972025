import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import CustomTag from '../../../components/custom-tag/customTag';
import { Api } from '../../../config/api/axiosInterceptors';
import { createDashboardQueryParams, formatDateTime, formatProspectStatus, getTagColor } from '../../../utils/utils';
import DataTable from '../../../components/dataTable/DataTable';

function ProspectsWithoutProgressList({ project_id, start_date, end_date }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [params, setParams] = useState({ project_id, start_date, end_date });

  const getProspects = async (page) => {
    setLoading(true);
    try {
      const dashboardParams = createDashboardQueryParams({ project_id, start_date, end_date });
      const paginationParams = new URLSearchParams({ page }).toString();

      // Combinar los parámetros
      const combinedParams = new URLSearchParams(dashboardParams);
      paginationParams.split('&').forEach((param) => {
        const [key, value] = param.split('=');
        combinedParams.set(key, value);
      });

      combinedParams.toString();

      const response = await Api.get(`/dashboard/without-daily-tracking?${combinedParams}`);
      const { prospects_statuses } = response.data;
      setData(prospects_statuses.data);

      setCurrentPage(prospects_statuses.current_page);
      setTotalData(prospects_statuses.total);
      setPageSize(prospects_statuses.per_page);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getProspects(currentPage);
  }, [project_id, start_date, end_date, currentPage]);

  const columns = [
    {
      title: 'Nombre del prospecto',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => `${record.name} ${record.last_name} ${record.second_last_name}`,
    },
    {
      title: 'Último seguimiento',
      dataIndex: 'last_sale_traking_date',
      key: 'last_sale_traking_date',
      render: (text, record) => {
        const color = getTagColor(text, record.type);
        return <CustomTag data={formatDateTime(text) || '-'} color={color} />;
      },
    },
    {
      title: 'Vendedor asignado',
      dataIndex: 'seller',
      key: 'seller',
      render: (text, record) =>
        `${record.seller_name} ${record.seller_last_name} ${record.seller_second_last_name || ''}`,
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      render: (text) => formatProspectStatus(text),
    },
  ];

  return (
    <Cards title="Seguimiento de prospectos">
      <DataTable
        loading={loading}
        columns={columns}
        data={data}
        currentPage={currentPage}
        totalItems={totalData}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    </Cards>
  );
}

export default ProspectsWithoutProgressList;
