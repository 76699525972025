// import { createStore, combineReducers, applyMiddleware } from 'redux';
// import rootReducer from './rootReducers';

// const store = createStore(
//   rootReducer,
// );

// export default store;
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducers';

// import userReducer from './reducers/userReducer';

// const rootReducer = combineReducers({
//   user: userReducer,
// });

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
