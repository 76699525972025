import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import myProfile from '../../container/myProfileManagement/myProfile';
import withAdminLayout from '../../layout/withAdminLayout';
import UserManagement from '../admin/userManagement';
import prospectManagement from '../seller/prospectsManagement';
import SalesTrackingManagement from '../seller/salesTrackingManagement';
import Dashboard from '../../container/dashboardBalken/Dashboard';

function Manager() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path={path} component={Dashboard} />
        <Route path={`${path}/dashboard`} component={Dashboard} />
        <Route path={`${path}/perfil`} component={myProfile} />
        <Route path={`${path}/gestion-usuarios`} component={UserManagement} />
        <Route path={`${path}/prospectos`} component={prospectManagement} />
        <Route path={`${path}/seguimiento-venta`} component={SalesTrackingManagement} />
      </Suspense>
    </Switch>
  );
}

export default withAdminLayout(Manager);
