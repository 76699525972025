import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const UserList = lazy(() => import('../../container/userManagement/UserList'));
const CreateUser = lazy(() => import('../../container/userManagement/CreateUser'));
const UserDetails = lazy(() => import('../../container/userManagement/UserDetails'));
const EditUser = lazy(() => import('../../container/userManagement/EditUser'));


function UserManagement() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={UserList} />
      <Route path={`${path}/crear`} component={CreateUser} />
      <Route path={`${path}/detalles/:userId`} component={UserDetails} />
      <Route path={`${path}/editar/:userId`} component={EditUser} />
    </Switch>
  );
}

export default UserManagement;
