import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import useRole from '../../hooks/useRole';
import { useRouteMatch } from 'react-router-dom';
function PrivateRoute({ component: Component, role, ...rest }) {
  const authState = useSelector((state) => state.authUser);
  // const authRole = useRole(); // Usa tu hook para obtener el rol del usuario
  const hasAccess = authState.isAuthenticated && role.split(',').includes(authState.role);
  const { path } = useRouteMatch();
  useRouteMatch;
  return <Route {...rest} render={(props) => (hasAccess ? <Component {...props} /> : <Redirect to={path} />)} />;
}

export default PrivateRoute;
