import { Col, Divider, Empty, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Api } from '../../../config/api/axiosInterceptors';
import { formatNumberWithCommas } from '../../../utils/utils';

function Sellers() {
  const [sellerData, setSellerData] = useState([]);
  const [loading, setLoading] = useState([]);

  const getSellerRanking = async () => {
    setLoading(true);
    try {
      const response = await Api.get('/dashboard/sellers-ranking');
      const { prospects_statuses } = response.data;
      setSellerData(prospects_statuses);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSellerRanking();
  }, []);

  return (
    <Cards title="VENDEDORES DESTACADOS">
      {loading ? (
        <Skeleton active />
      ) : sellerData?.length > 0 ? (
        <Row gutter={[25, 25]}>
          {sellerData.map((item, index) => (
            <Col xs={24} sm={24} md={12} lg={8} key={index}>
              <div className="card-bordered">
                <div className="card-number">{index + 1}</div>
                <div className="card-data">
                  <span className="card-name">{item.seller}</span>
                  <div className="card-flex">
                    <span className="card-info">${formatNumberWithCommas(item.ammount)}</span>
                    <Divider type="vertical" className="card-divider" />
                    <span className="card-info">{`${item.sales} ${item.sales > 1 ? 'prospectos' : 'prospecto'}`}</span>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Cards>
  );
}

export default Sellers;
