import { message } from 'antd';
import axios from 'axios';
import Storage from '../../utils/storage';

const API_URL = process.env.REACT_APP_API_URL;

const client = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const showError = (content, duration = 10) => {
  message.error({
    content,
    duration,
  });
};

const refreshToken = async () => {
  try {
    const response = await axios.post(`${API_URL}/refreshToken`, {
      token: Storage.getRefreshToken(),
    });
    if (response.status === 200) {
      const newToken = response.data.token;
      Storage.setItem('token', newToken);
      return newToken;
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    showError('Error al actualizar. Por favor inicia sesión nuevamente.');
    // Redirigir al usuario a la página de login y limpiar el almacenamiento
    Storage.clear();
    window.location.href = '/login';
    // history.push(`/`);
  }
  return null;
};

client.interceptors.request.use(
  (config) => {
    const token = Storage.getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response } = error;
    const originalRequest = error.config;

    if (response) {
      if (response.status === 401) {
        const newToken = await refreshToken();
        if (newToken) {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axios(originalRequest);
        }
      } else if (response.status >= 400 && response.status < 500) {
        // showError(`Error: ${response.data.message || response.statusText}`);
      } else if (response.status >= 500) {
        // showError('Error. Por favor, inténtelo de nuevo más tarde.');
      }
    } else {
      showError('Error de red. Por favor verifique su conexión.');
    }
    return Promise.reject(error);
  },
);

class Api {
  static get(endpoint = '', config = {}) {
    return client.get(endpoint, config);
  }

  static post(endpoint = '', data = {}, config = {}) {
    return client.post(endpoint, data, config);
  }

  static patch(endpoint = '', data = {}, config = {}) {
    return client.patch(endpoint, data, config);
  }

  static delete(endpoint = '', config = {}) {
    return client.delete(endpoint, { data: config.data, ...config });
  }

  static put(endpoint = '', data = {}, config = {}) {
    return client.put(endpoint, data, config);
  }
}

export { Api };

