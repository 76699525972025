import { combineReducers } from 'redux';
import ChangeLayoutMode from './themeLayout/reducers';
import authUserReducer from './authUser/reducers';
import chartContentReducer from './chartContent/reducers';

const rootReducers = combineReducers({
  authUser: authUserReducer,
  ChangeLayoutMode,
  chartContent: chartContentReducer,
});

export default rootReducers;
