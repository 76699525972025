import React from 'react';
import { Table, Pagination, Spin } from 'antd';

const DataTable = ({ loading, columns, data, currentPage, totalItems, pageSize, onPageChange }) => {
  return (
    <>
      <Spin spinning={loading}>
        <Table
          className="table-responsive"
          pagination={false}
          columns={columns}
          dataSource={data}
        />
        <div className="pagination-container">
          <Pagination
            current={currentPage}
            total={totalItems}
            pageSize={pageSize}
            onChange={onPageChange}
            showSizeChanger={false} // Asegúrate de que esto también esté establecido en false
          />
        </div>
      </Spin>
    </>
  );
};

export default DataTable;
