// import { Tag } from 'antd';
import { Tag } from 'antd';
import React from 'react';

function CustomTag({ data, color }) {
  const getColor = (color) => {
    switch (color) {
      case 'green':
        return 'tag-green';
      case 'yellow':
        return 'tag-yellow';
      case 'red':
        return 'tag-red';
      default:
        return 'tag-default';
    }
  };

  return <Tag className={`custom-tag ${getColor(color)}`}>{data}</Tag>;
}

export default CustomTag;
