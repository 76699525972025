import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import CustomerView from '../../components/customerView';
import myProfile from '../../container/myProfileManagement/myProfile';
import withAdminLayout from '../../layout/withAdminLayout';
import CustomerInfoManagement from './CustomerInfoManagement';
import paymentsManagment from '../admin/paymentsManagement';
import ticketsManagement from '../admin/ticketsManagement'
function Customer() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`${path}`} component={CustomerInfoManagement} />
        <Route path={`${path}/perfil`} component={myProfile} />
        <Route path={`${path}/expediente`} component={CustomerInfoManagement} />
        <Route path={`${path}/pagos`} component={paymentsManagment} />
        <Route path={`${path}/tickets`} component={ticketsManagement} />

      </Suspense>
    </Switch>
  );
}

export default withAdminLayout(Customer);
