import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const CreateSalesTracking = lazy(() => import('../../container/salesTrackingManagement/CreateSalesTracking'));
const EditSalesTracking = lazy(() => import('../../container/salesTrackingManagement/EditSalesTracking'));
const SalesTrackingList = lazy(() => import('../../container/salesTrackingManagement/SalesTrackingList'));

function SalesTrackingManagement() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={SalesTrackingList} />
      <Route path={`${path}/crear`} component={CreateSalesTracking} />
      {/* <Route path={`${path}/editar/:id`} component={EditSalesTracking} /> */}
    </Switch>
  );
}

export default SalesTrackingManagement;
