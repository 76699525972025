import { openNotification } from '../../utils/notificationHandler';
import { Api } from './axiosInterceptors';

export const getAllPaginatedData = async (url, dataKey, setData, param='') => {
  let allData = [];
  let currentPage = 1;
  let totalPages = 1;

  try {
    // const response = await Api.get(`${url}?page=${currentPage}`);
    // response -> data -> dataKey -> data
    do {
      const response = await Api.get(`${url}?page=${currentPage}${param}`);
      const responseData = response.data;
      const data = responseData[dataKey];
      const currentPageData = data.data;
      const lastPage = data.last_page;

      allData = [...allData, ...currentPageData];
      totalPages = lastPage;
      currentPage += 1;
    } while (currentPage <= totalPages);
    setData(allData);
  } catch (error) {
    console.error('Error fetching paginated data:', error);
  }
};

export const getCataloguesByType = async (type, setData) => {
  try {
    const response = await Api.get(`/catalogs?type=${type}`);
    setData(response.data.catalogs);
  } catch (error) {
    console.error('Error:', error);
    openNotification('error', 'No se pudieron obtener los tipos de inmueble.');
  }
};

export const getData = async (url, dataKey, setData) => {
  try {
    const response = await Api.get(`${url}`);
    setData(response.data[dataKey]);
  } catch (error) {
    console.error('Error:', error);
    setData([]);
    // openNotification('error', `No se pudieron obtener los datos: ${error?.response?.data?.message}`);
  }
};
