import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import PrivateRoute from '../../components/utilities/privateRoute';

const CreateProspect = lazy(() => import('../../container/prospectsManagement/CreateProspect'));
const EditProspect = lazy(() => import('../../container/prospectsManagement/EditProspect'));
const ProspectDetails = lazy(() => import('../../container/prospectsManagement/ProspectDetails'));
const ProspectsList = lazy(() => import('../../container/prospectsManagement/ProspectsList'));
const CreateEstateAssign = lazy(() => import('../../container/estateAssignmentManagement/CreateEstateAssign'));
const CreateDocumentation = lazy(() => import('../../container/prospectsManagement/CreateProspectDocumentation'));
const CreateSalesTrackingFromParams = lazy(() =>
  import('../../container/salesTrackingManagement/CreateSalesTrackingFromParams'),
);

function ProspectsManagement() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute exact path={path} component={ProspectsList} role="administrador,gerente_comercial,vendedor" />
      <PrivateRoute path={`${path}/crear`} component={CreateProspect} role="gerente_comercial,vendedor" />
      <PrivateRoute path={`${path}/editar/:prospectId`} component={EditProspect} role="gerente_comercial,vendedor" />
      <PrivateRoute
        path={`${path}/detalles/:prospect_id`}
        component={ProspectDetails}
        role="administrador,gerente_comercial,vendedor"
      />
      <PrivateRoute
        path={`${path}/asignacion-inmueble/:id`}
        component={CreateEstateAssign}
        role="gerente_comercial,vendedor"
      />
      <PrivateRoute
        path={`${path}/seguimiento-venta/:id`}
        component={CreateSalesTrackingFromParams}
        role="gerente_comercial,vendedor"
      />
      <PrivateRoute
        path={`${path}/agregar-documentacion/:id`}
        component={() => <CreateDocumentation isAddDocumentation={true} />}
        role="gerente_comercial,vendedor"
      />
      <PrivateRoute
        path={`${path}/cierre-venta/:id`}
        // component={CreateDocumentation}
        component={() => <CreateDocumentation isAddDocumentation={false} />}
        role="gerente_comercial,vendedor"
      />
    </Switch>
  );
}

export default ProspectsManagement;
