import { Empty, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { ChartjsDonut } from '../../../components/charts/chartjs';
import { Api } from '../../../config/api/axiosInterceptors';
import { createDashboardQueryParams, formatProspectStatus } from '../../../utils/utils';
import { Wrapper } from '../style';
import ProspectStatusList from './ProspectsByStage/ProspectStatusList';
function ProspectsByStage({ project_id, start_date, end_date }) {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalProspects, setTotalProspects] = useState(null);
  const getProspectsByState = async () => {
    setLoading(true);
    try {
      const queryParams = createDashboardQueryParams({ project_id, start_date, end_date });
      const response = await Api.get(`/dashboard/prospects-by-status?${queryParams}`);

      // Destructuring con valores por defecto
      const {
        total = 0,
        prospecto = 0,
        propiedad_asignada = 0,
        seguimiento_de_venta = 0,
        documentacion = 0,
        // finalizado = 0,
      } = response.data.prospects_statuses || {};

      setTotalProspects(total);

      const labels = formatProspectStatus([
        'prospecto',
        'propiedad_asignada',
        'seguimiento_de_venta',
        'documentacion',
        // 'finalizado',
      ]);

      const data = [
        prospecto,
        propiedad_asignada,
        seguimiento_de_venta,
        documentacion,
        // finalizado
      ];
      const datasets = [
        {
          data,
          backgroundColor: [
            '#FDD3AA', // Color más claro
            '#FBD772',
            '#F5BC2A',
            '#F98A54',
            // '#F66B20', // Color más oscuro
          ],
        },
      ];

      const tableData = [
        {
          stage: labels[0],
          amount: prospecto,
          percentage: getPercentage(prospecto, total),
          color: '#FDD3AA',
        },
        {
          stage: labels[1],
          amount: propiedad_asignada,
          percentage: getPercentage(propiedad_asignada, total),
          color: '#FBD772',
        },
        {
          stage: labels[2],
          amount: seguimiento_de_venta,
          percentage: getPercentage(seguimiento_de_venta, total),
          color: '#F5BC2A',
        },
        {
          stage: labels[3],
          amount: documentacion,
          percentage: getPercentage(documentacion, total),
          color: '#F98A54',
        },
        // {
        //   stage: labels[4],
        //   amount: finalizado,
        //   percentage: getPercentage(finalizado, total),
        //   color: '#F66B20',
        // },
      ];
      setChartData({ labels, datasets, tableData });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getPercentage = (amount, total) => {
    if (!total || total === 0 || amount === null || amount === undefined) {
      return '0%';
    }
    return `${((amount * 100) / total).toFixed(2)}%`;
  };

  useEffect(() => {
    getProspectsByState();
  }, [project_id, start_date, end_date]);

  return (
    <Wrapper>
      <Cards title="PROSPECTOS POR ETAPA">
        {loading ? (
          <Skeleton active />
        ) : chartData.datasets?.length > 0 && chartData.datasets[0].data.length > 0 ? (
          <div className="chart">
            <p>
              Total de prospectos: <span className="bold">{totalProspects}</span>
            </p>

            {chartData.datasets[0].data.every((value) => value === 0) ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No hay datos disponibles para mostrar" />
            ) : (
              <ChartjsDonut labels={chartData.labels} datasets={chartData.datasets} />
            )}

            <ProspectStatusList datasource={chartData.tableData} />
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Cards>
    </Wrapper>
  );
}

export default ProspectsByStage;
