import { Badge, Table } from 'antd';
import React, { useState } from 'react';

function ProspectStatusList({ datasource }) {

  const columns = [
    {
      title: 'Etapa ',
      dataIndex: 'stage',
      key: 'stage',
      render: (text, render) => (
        <>
          <Badge color={render.color} size="default" style={{ marginRight: '1rem' }} />
          <span>{render.stage}</span>
        </>
      ),
    },
    {
      title: 'Cantidad',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Porcentaje',
      dataIndex: 'percentage',
      key: 'percentage',
    },
  ];

  return <Table loading={false} columns={columns} dataSource={datasource} pagination={false} />;
}

export default ProspectStatusList;
