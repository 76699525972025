import {Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Heading from '../../../../components/heading/heading';
import { Api } from '../../../../config/api/axiosInterceptors';
import { login } from '../../../../redux/authUser/actions';
import logoBalken from '../../../../static/img/balken/balken_logo_BALKEN.png';
import Storage from '../../../../utils/storage';
import { generateRequiredRule } from '../../../../utils/validationRules';
import { AuthWrapper } from './style'

function SignIn() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [error, setError] = useState(null); // Estado para manejar el error
  const [loading, setLoading] = useState(false);

  const emailRules = [generateRequiredRule('correo electrónico')];
  const passwordRules = [generateRequiredRule('contraseña')];

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await Api.post('/login', data);
      const { token, user } = response.data;
      Storage.setItem('token', token);
      await dispatch(login(user));
      history.push(`${user.roles[0].name}`);
    } catch (err) {
      setError(
        'El correo electrónico o contraseña son incorrectos, intenta ingresarlos nuevamente o restablece tu contraseña.',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
          <img className="balken-logo" src={logoBalken} alt="" />
          <Heading as="h3">
            <strong className="heading-title">Inicio de sesión</strong>
            <p className="secondary-text">
              Bienvenido nuevamente, ingresa tu correo electrónico y contraseña para acceder al sitio.
            </p>
          </Heading>
          <Form.Item name="email" rules={emailRules} label="Correo electrónico">
            <Input />
          </Form.Item>
          <Form.Item name="password" rules={passwordRules} label="Contraseña">
            <Input.Password />
          </Form.Item>
          {error && <p style={{ color: 'red' }}>{error}</p>}

          <Button block htmlType="submit" type="primary" size="large">
            {loading ? 'Cargando...' : 'Inicia sesión'}
          </Button>

          <div className="auth-form-action mt-20">
            <NavLink className="forgot-pass-text" to="/recuperar-contrasena">
              ¿Olvidaste tu contraseña?
            </NavLink>
          </div>
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default SignIn;
