import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import PrivateRoute from '../../components/utilities/privateRoute';

const TicketsList = lazy(() => import('../../container/ticketsManagement/TicketsList'));
const CreateTicket = lazy(() => import('../../container/ticketsManagement/CreateTicket'));
const TicketDetails = lazy(() => import('../../container/ticketsManagement/TicketDetails'));

function paymentsManagment() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={TicketsList} />
      <PrivateRoute path={`${path}/crear`} component={CreateTicket} role="cliente" />
      <PrivateRoute path={`${path}/detalles/:id`} component={TicketDetails} role="administrador,cliente" />
    </Switch>
  );
}

export default paymentsManagment;
