import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import PrivateRoute from '../../components/utilities/privateRoute';

const PaymentList = lazy(() => import('../../container/paymentsManagement/PaymentsList'));
const CreatePayment = lazy(() => import('../../container/paymentsManagement/CreatePayment'));
const PaymentDetails = lazy(() => import('../../container/paymentsManagement/PaymentDetails'));
const EditPayment = lazy(() => import('../../container/paymentsManagement/EditPayment'));

function paymentsManagment() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={PaymentList} />
      <PrivateRoute path={`${path}/crear`} component={CreatePayment} role="administrador" />
      <PrivateRoute path={`${path}/detalles/:paymentId`} component={PaymentDetails} role="administrador,cliente" />
    </Switch>
  );
}

export default paymentsManagment;
