import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const EstatesList = lazy(() => import('../../container/statesManagement/EstatesList'));
const CreateEstate = lazy(() => import('../../container/statesManagement/CreateEstate'));
const DetailsEstate = lazy(() => import('../../container/statesManagement/DetailsEstate'));
const EditEstate = lazy(() => import('../../container/statesManagement/EditEstate'));

function InmueblesManagement() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={EstatesList} />
      <Route path={`${path}/crear`} component={CreateEstate} />
      <Route path={`${path}/detalles/:estateId`} component={DetailsEstate} />
      <Route path={`${path}/editar/:estateId`} component={EditEstate} />
    </Switch>
  );
}

export default InmueblesManagement;
