import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import esES from 'antd/es/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es';
import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import PrivateRoute from './components/utilities/privateRoute';
import config from './config/config';
import { loadAuthState } from './redux/authUser/actions';
import store from './redux/store';
import './static/css/style.css';

// ------- Auth
import Login from './container/profile/authentication/overview/SignIn';
import ForgotPassword from './container/profile/authentication/overview/ForgotPassword';
import ResetPassword from './container/profile/authentication/overview/ResetPassword';
import AuthLayout from './container/profile/authentication/Index';
// ------- Roles
import Admin from './routes/admin';
import Customer from './routes/customer';
import Seller from './routes/seller';
import Manager from './routes/manager';

const { theme } = config;
moment.locale('es');

const ProviderConfig = () => {
  const dispatch = useDispatch();
  const { rtl, topMenu, darkMode, authState } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      authState: state.authUser,
    };
  });

  useEffect(() => {
    dispatch(loadAuthState());
  }, [dispatch]);

  return (
    <ConfigProvider locale={esES}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router>
          <Switch>
            <Route path="/login" component={AuthLayout(Login)} />
            <Route path="/recuperar-contrasena" component={AuthLayout(ForgotPassword)} />
            <Route path="/restablecer" component={AuthLayout(ResetPassword)} />
            <PrivateRoute path="/administrador" component={Admin} role="administrador" />
            <PrivateRoute path="/cliente" component={Customer} role="cliente" />
            <PrivateRoute path="/vendedor" component={Seller} role="vendedor" />
            <PrivateRoute path="/gerente_comercial" component={Manager} role="gerente_comercial" />
            <Redirect from="/" to={authState.isAuthenticated ? `/${authState.role}` : '/login'} />
          </Switch>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
