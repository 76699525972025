import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const CreateProject = lazy(() => import('../../container/projectManagement/CreateProject'));
const ProjectList = lazy(() => import('../../container/projectManagement/ProjectList'));
const ProjectDetail = lazy(() => import('../../container/projectManagement/ProjectDetail'));
const EditProject = lazy(() => import('../../container/projectManagement/EditProject'));

function DashboardRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ProjectList} />
      <Route path={`${path}/crear`} component={CreateProject} />
      <Route path={`${path}/detalles/:id`} component={ProjectDetail} />
      <Route path={`${path}/editar/:id`} component={EditProject} />

    </Switch>
  );
}

export default DashboardRoutes;
