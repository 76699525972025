import { Select, Spin } from 'antd';
import React from 'react';
import useSelectOptions from '../../hooks/useSelectOptions';

const { Option } = Select;

const CustomSelect = ({ url, datakey, param, value, onChange, ...props }) => {
  const { options, loading } = useSelectOptions(url, datakey, param, {id: "null", label: "Sin vendedor asignado"});

  if (loading) {
    return <Spin />;
  }

  return (
    <Select value={value} onChange={onChange} {...props}>
      {options.map((option, index) => (
        <Option key={index} value={option.id}>
          {option?.label || `${option?.name} ${option?.last_name || ''} ${option?.second_last_name || ''}` || ''}
        </Option>
      ))}
    </Select>
  );
};

export default CustomSelect;
