import { Button, Col, Form, Input, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button as ButtonDark } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Api } from '../../config/api/axiosInterceptors';
import logoutIcon from '../../static/img/balken/cerrar_sesion_BALKEN.svg';
import { openNotification } from '../../utils/notificationHandler';
import Storage from '../../utils/storage';
import { passwordRule } from '../../utils/validationRules';
import { BasicFormWrapper, Main } from '../styled';
const { Text, Paragraph } = Typography;
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../redux/authentication/actionCreator';

function MyProfile() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const getUserProfile = async () => {
    setLoading(true);
    try {
      const response = await Api.get(`/profile`);
      const { user } = response.data;
      setUser(user);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await Api.put(`/change-password`, data);
      if (response.data.success) {
        openNotification('success', 'Contraseña modificada correctamente');
        form.resetFields();
      } else {
        openNotification('error', 'Error al cambiar la contraseña', 'Por favor, inténtelo de nuevo.');
      }
    } catch (error) {
      console.error('Error changing password:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      const response = await Api.post('/logout');
      if (response.data.success) {
        Storage.clear();
        await dispatch(logOut());
        // history.push('/'); // Redirige al login tras cerrar sesión
      } else {
        openNotification('error', 'Error al cerrar sesión', 'Por favor, inténtelo mas tarde.');
      }
    } catch (error) {
      console.error('Error changing password:', error);
    } finally {
      setLoading(false);
    }
  };

  const validatePasswordConfirmation = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Las contraseñas no coinciden.'));
    },
  });

  const [form] = Form.useForm();

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <PageHeader
        title="Mi perfil"
        // buttons={[
        //   <ButtonDark outlined type="dark" size="large" onClick={handleLogout}>
        //     <img src={logoutIcon} alt="cerrar sesión" />
        //     Cerrar sesión
        //   </ButtonDark>,
        // ]}
      />
      <Main>
        <Spin spinning={loading}>
          <Row gutter={20}>
            <Col lg={12} md={12} xs={24}>
              <Cards title="Información" gutter={15}>
                <div className="mb-10">
                  <Text>Nombre: </Text>
                  <Text strong>
                    {user.name} {user.last_name} {user.second_last_name}
                  </Text>
                </div>
                <div className="mb-10">
                  <Text>Usuario: </Text>
                  <Text strong>{user.email}</Text>
                </div>
                <div className="mb-10">
                  <Text>Teléfono: </Text>
                  <Text strong>{user.cell_phone}</Text>
                </div>
              </Cards>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <BasicFormWrapper>
                <Cards title="Cambiar contraseña">
                  <Form layout="horizontal" form={form} onFinish={onSubmit}>
                    <Row gutter={25}>
                      <Col md={18} xs={24} className="mb-20">
                        <Form.Item name="password" label="Contraseña" rules={passwordRule}>
                          <Input.Password />
                        </Form.Item>
                      </Col>
                      <Col md={18} xs={24} className="mb-20">
                        <Form.Item
                          name="password_confirmation"
                          label="Confirmar contraseña"
                          dependencies={['password']}
                          rules={[
                            { required: true, message: 'Por favor confirma tu contraseña' },
                            validatePasswordConfirmation,
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div className="sDash_form-action">
                      <Button type="primary" htmlType="submit" size="large">
                        Cambiar contraseña
                      </Button>
                      <Button type="light" htmlType="button" size="large">
                        Cancelar
                      </Button>
                    </div>
                  </Form>
                </Cards>
              </BasicFormWrapper>
            </Col>
          </Row>
        </Spin>
      </Main>
    </>
  );
}

export default MyProfile;
